// import Plyr from "plyr";
import { htmlToElement } from "../../../wp-utils/v1";

import { produce } from "immer";

class MyPlayer {
  constructor(media, options, features = {}, isPremium = false) {
    this.media = media;
    this.isPremium = isPremium;
    this.features = features;
    this.options = produce(options, (draft) => {
      draft.markers.enabled = true;
      draft.speed.options = options.speed.options.map((item) => parseFloat(item));
      draft.ads.enabled = false;
      draft.fullscreen = {
        container: ".plyr_wrapper",
        iosNative: true,
      };
      // draft.captions = { active: false, language: "auto", update: true };
    });

    if (!options.urls.enabled) {
      this.options = produce(this.options, (draft) => {
        draft.urls.download = null;
      });
    }

    if (this.media.src?.split(".").pop() === "m3u8") {
      this.videoHls();
    }

    if (this.media.src?.split(".").pop() === "mpd") {
      this.videoDash();
    }

    if (this.media.closest(".plyr_wrapper")?.offsetWidth <= 425) {
      this.options = produce(this.options, (draft) => {
        draft.controls = this.options.controls.filter((control) => !["fast-forward", "restart", "rewind"].includes(control));
      });
    }

    if (this.options.muted) {
      this.options = produce(this.options, (draft) => {
        draft.volume = 0;
        draft.storage.enabled = false;
        // captions will not work properly
      });
    } else {
      this.options = produce(this.options, (draft) => {
        draft.volume = 0.5;
        draft.storage.enabled = true;
      });
    }

    this.player = new Plyr(this.media, this.options);
    // window.options = this.options;
    // window.features = this.features;
    // window.player = this.player;
    this.init();

    this.player.on("pause", () => {
      if (this.features.disablePause) {
        this.player.play();
      }
    });
  }

  setIsPremium(isPremium) {
    this.isPremium = isPremium;
  }

  init() {
    if (!this.player) {
      console.error("something went wrong!");
      return;
    }

    setTimeout(() => {
      if (!this.isPremium && ["youtube", "vimeo"].includes(this.player.provider)) {
        this.destroy();
      }
    }, 10000);

    const sources = this.features.qualities.map((item) => {
      return {
        type: "video/mp4",
        size: item.size,
        src: item.video_file,
      };
    });
    const tracks = this.features.captions.map((item, index) => {
      const info = item.label.split("/");
      return {
        kind: "captions",
        size: item.size,
        label: info[0],
        srclang: info[1],
        src: item.caption_file,
        // default: index === 0,
      };
    });

    this.player.download = this.player.source;

    this.player.source = {
      type: "video",
      sources: [
        {
          src: this.player.source,
          type: "video/mp4",
          size: 720,
        },
        ...sources,
      ],
      poster: this.player.poster,
      // tracks,
    };

    tracks.map((item, index) => {
      const track = document.createElement("track");
      Object.assign(track, item);
      this.player.media.appendChild(track);
    });

    this.player.on("play", () => {
      if (window.location.pathname.includes("/wp-admin")) {
        this.player.pause();
        this.player.currentTime = 0;
      }
    });

    if (typeof this.features === "object") {
      this.makeElementSticky(this.features.sticky);
      this.createChapter();
      this.watermark(this.features.watermark);
      this.thumbInPause(this.features?.thumbInPause);
      this.autoplayWhenVisible();
      this.pauseOther();
      this.createView();
    }

    this.player.on("play", () => {
      if (this.disabled) {
        this.player.currentTime = 0;
        this.player.pause();
      }
    });

    this.player.on("ready", () => {
      this.media.plyr = this.player;
      if (this.features.startTime) {
        setTimeout(() => {
          this.player.currentTime = parseInt(this.features.startTime);
        }, 200);
      }

      if (this.options.autoplay) {
        this.player.play();
      }

      // this.player.fullscreen.container = this.player.elements.container.parentNode;
      this.controlTouchAction();
    });
  }

  /**
   *
   * @param {media player} player
   * @param {int} videoId
   * @param {string} type
   * @param {boolean} interval
   */
  createView(interval = false) {
    this.player.on("pause", () => {
      if (!this.videoId || !this.isPremium) {
        return;
      }
      let pending = false;
      const currentTime = this.player.currentTime;
      let maxViewed = localStorage.getItem(`video-progress-max-${this.videoId}`);
      let created_at = new Date();

      if (!maxViewed) {
        localStorage.setItem(`video-progress-max-${this.videoId}`, JSON.stringify({ currentTime, created_at }));
      } else {
        maxViewed = JSON.parse(maxViewed);
      }

      const viewCreated = (created_at - new Date(maxViewed?.created_at)) / (1000 * 60);
      let condition = false;
      if (interval) {
        condition = this.player.playing && (currentTime >= maxViewed?.currentTime || viewCreated > 1440);
      } else {
        condition = currentTime >= maxViewed?.currentTime || viewCreated > 1440;
      }

      if (condition) {
        if (viewCreated > 1440) {
          maxViewed.created_at = created_at;
        }

        maxViewed.currentTime = this.player.currentTime;
        localStorage.setItem(`video-progress-max-${this.videoId}`, JSON.stringify(maxViewed));

        if (!pending) {
          pending = true;
          wp.ajax
            .post("h5vp_ajax_handler", {
              nonce: this.nonce,
              model: "view",
              method: "createOrUpdate",
              video_id: this.videoId,
              // type: this.player.provider,
              duration: parseInt(this.player.currentTime),
            })
            .done((res) => {
              console.log(res);
            })
            .fail((res) => {
              console.log(res);
            });
        }
      }
    });
  }

  autoplayWhenVisible() {
    if (this.features.playWhenVisible) {
      let scrollPlayed = false;
      this.player.on("ready", () => {
        window.addEventListener("scroll", () => {
          if (!this.isPremium) {
            return false;
          }
          if (this.player.elements?.container?.offsetParent?.offsetTop < window.scrollY && !scrollPlayed) {
            this.player.muted = true;
            this.player.play();
            scrollPlayed = true;
          }
        });
      });
    }
  }

  /**
   * handle sticky feature
   * @param {Sticky {}} sticky
   * @returns
   */
  makeElementSticky(sticky) {
    if (!sticky?.enabled) {
      return false;
    }
    const element = this.player.elements.fullscreen;
    if (element && this.player) {
      const originalTop = element.getBoundingClientRect().top;
      const closeBtn = htmlToElement("<span class='close'>&times;</span>");

      window.addEventListener("scroll", () => {
        if (!this.isPremium) {
          return;
        }
        const closeBtnExists = element.querySelector(".close");
        const scrollTop = window.scrollY;
        if (scrollTop >= originalTop && this.player.playing) {
          element.classList.add(sticky.position);
          element.classList.add("sticky");
          if (!closeBtnExists) {
            element.appendChild(closeBtn);
          }
        } else if (!this.player.playing) {
          element.classList.remove("sticky");
          element.classList.remove(sticky.position);
        }
        closeBtn.addEventListener("click", () => {
          element.classList.remove(sticky.position);
          element.classList.remove("sticky");
          this.player.pause();
        });
      });
    }
  }

  /**
   * handle chapter feature
   * @param {Chapters[]} chapters
   * @returns
   */
  createChapter() {
    const wrapper = this.player?.elements?.container;
    const chapters = this.options.markers.points.map((point) => ({ ...point, time: this.chapterTimeToSeconds(point.time) }));
    if (!Array.isArray(chapters) || !wrapper) {
      return false;
    }

    const exists = wrapper.querySelector(".h5vp-chapter-wrapper");
    if (!chapters || exists || !chapters.length) {
      return false;
    }
    const chapterWrapper = document.createElement("div");
    const chapterListWrapper = document.createElement("div");
    const markerControl = document.createElement("button");
    const chapterList = document.createElement("div");

    markerControl.classList = "h5vp-chapter-button plyr__controls__item plyr__control";
    markerControl.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 487.3 487.3"> <path d="M487.2,69.7c0,12.9-10.5,23.4-23.4,23.4h-322c-12.9,0-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4h322.1 C476.8,46.4,487.2,56.8,487.2,69.7z M463.9,162.3H141.8c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1 c12.9,0,23.4-10.5,23.4-23.4C487.2,172.8,476.8,162.3,463.9,162.3z M463.9,278.3H141.8c-12.9,0-23.4,10.5-23.4,23.4 s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,288.8,476.8,278.3,463.9,278.3z M463.9,394.3H141.8 c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,404.8,476.8,394.3,463.9,394.3z M38.9,30.8C17.4,30.8,0,48.2,0,69.7s17.4,39,38.9,39s38.9-17.5,38.9-39S60.4,30.8,38.9,30.8z M38.9,146.8 C17.4,146.8,0,164.2,0,185.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,146.8,38.9,146.8z M38.9,262.8 C17.4,262.8,0,280.2,0,301.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,262.8,38.9,262.8z M38.9,378.7 C17.4,378.7,0,396.1,0,417.6s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9C77.8,396.2,60.4,378.7,38.9,378.7z"/> </svg><span class="plyr__tooltip">Chapter</span>`;
    chapterWrapper.classList = "h5vp-chapter-wrapper";
    chapterListWrapper.classList = "h5vp-chapter-list-wrapper";
    chapterList.classList = "h5vp-chapter-list";

    chapters.map((point) => {
      if (point?.label != "" && point?.time != "") {
        const time = this.chapterTimeToSeconds(point?.time);
        const chapterItem = document.createElement("button");

        //chapter item
        chapterItem.innerHTML = `<span>${point?.label}</span>`;
        chapterList.appendChild(chapterItem);

        chapterItem.onclick = () => {
          chapterList.classList.remove("showing");
          this.player.currentTime = time;
          this.player.play();
        };

        markerControl.onclick = () => {
          if (chapterList.classList.contains("showing")) {
            chapterList.classList.remove("showing");
          } else {
            chapterList.classList.add("showing");
          }
        };

        wrapper.addEventListener("click", function (event) {
          if (!chapterList.contains(event.target) && !markerControl.contains(event.target)) {
            if (chapterList.classList.contains("showing")) {
              chapterList.classList.remove("showing");
            }
          }
        });

        this.onClick(wrapper.querySelector(".plyr__controls__item.plyr__menu button"), function () {
          if (chapterList.classList.contains("showing")) {
            chapterList.classList.remove("showing");
          }
        });
      }
    });

    chapterListWrapper?.appendChild(chapterList);
    this.player.on("ready", () => {
      const volumeElem = wrapper.querySelector(".plyr__controls__item.plyr__volume");
      const menu = wrapper.querySelector(".plyr__controls__item.plyr__menu");
      const fullscreen = wrapper.querySelector(".plyr__controls__item[data-plyr='fullscreen']");
      const duration = wrapper.querySelector(".plyr__controls__item.plyr__time--current.plyr__time");
      if (volumeElem) {
        volumeElem.before(markerControl);
      } else if (menu) {
        menu.before(markerControl);
      } else if (fullscreen) {
        fullscreen.before(markerControl);
      } else if (duration) {
        duration.before(markerControl);
      }
      wrapper.querySelector(".plyr__controls")?.after(chapterListWrapper);
    });
  }

  /**
   * handle watermark feature
   * @param {watermark Object} watermark
   * @returns
   */

  watermark(watermark = {}) {
    const { enabled, type, text, color } = watermark;
    const plyrWrapper = this.player.elements?.container;
    if (!plyrWrapper || !enabled) {
      return false;
    }

    // I didn't find the reason to disable
    let content = text;

    setTimeout(async () => {
      if (["email", "name"].includes(type)) {
        try {
          this.disabled = true;
          const response = await wp.ajax.post("watermark_data", { nonce: this.nonce });
          content = response.user[type];
          this.disabled = false;
        } catch (error) {
          this.disabled = false;
        }
      }

      // let content = "";
      let tag = "watermark";
      this.player.on("play", () => {
        if (!this.isPremium) {
          return false;
        }
        const watermarkInterval = setInterval(() => {
          const watermarkElement = document.querySelector(tag);
          if (watermarkElement) {
            plyrWrapper.removeChild(watermarkElement);
          }
          tag = "watermark" + Math.round(Math.random() * 1000);
          const span = document.createElement(tag);
          span.setAttribute("style", "display: block !important");
          if (type === "email") {
            span.innerText = content;
          }
          plyrWrapper.appendChild(span);
          span.innerText = content;
          span.setAttribute(
            "style",
            `position:absolute; display:block !important; opacity:1 !important;top: ${Math.round(Math.random() * 90)}% !important; left: ${Math.round(
              Math.random() * 70
            )}% !important; transform: none !important`
          );
          span.style.color = color;

          plyrWrapper.appendChild(span);
        }, 2000);

        this.player.on("pause", () => {
          setTimeout(() => {
            clearInterval(watermarkInterval);
            const watermarkElement = document.querySelector(tag);
            if (watermarkElement) {
              plyrWrapper.removeChild(watermarkElement);
            }
          }, 0);
        });
      });
    }, 100);
  }

  /**
   * handle show thumbnail in video pause feature
   * @param {*} wrapper
   * @param {*} player
   * @param {*} infos
   */
  thumbInPause() {
    if (!this.features?.thumbInPause?.enabled) {
      return;
    }

    if (this.features.thumbInPause?.type == "custom") {
      const img = document.createElement("img");
      img.src = this.player.poster;
      const overlaid = this.player?.elements?.buttons?.play?.[0];
      this.player.on("ready", () => {
        const overlaid = this.player?.elements?.buttons?.play?.[0];
        if (overlaid) {
          overlaid.classList.add("thumbInPause");
          overlaid.appendChild(img);
        }
      });

      this.player.on("play", () => {
        overlaid.classList.remove("thumbInPause");
      });
      this.player.on("pause", () => {
        overlaid.classList.add("thumbInPause");
      });
    } else {
      this.player.on("pause", () => {
        if (this.player?.elements?.container) {
          this.player?.elements?.container.classList.add("plyr--stopped");
        }
      });
    }
  }

  /**
   * destroy player
   */
  destroy() {
    this.player?.destroy();
    console.log("destroyed");
  }

  /**
   *
   * @param {String} value
   * @returns {Int}
   */
  chapterTimeToSeconds(value = "") {
    const time = value.toString().match(/(\d+):(\d+)/);
    if (time == null) {
      return parseInt(value);
    }
    return parseInt(time[1]) * 60 + parseInt(time[2]);
  }

  getTTT(f = 10, s = 10) {
    return (f / 100) * s - (f / 100) * (100 - s);
  }

  onClick(element, callback) {
    if (element) {
      // element.onclick = () => callback();
      element.addEventListener("click", callback);
    }
  }

  /**
   *  set nonce which will be used during ajax requests
   * @param {string} nonce
   */
  setNonce(nonce) {
    this.nonce = nonce;
  }
  /**
   *  set videoId which will be used during view count
   * @param {string} videoId
   */
  setVideoId(videoId) {
    this.videoId = videoId;
  }

  videoHls() {
    const initializeHls = () => {
      if (!window.Hls?.isSupported()) {
        console.warn("Hls does not support");
      } else {
        // For more Hls.js options, see https://github.com/dailymotion/hls.js
        const hls = new Hls();
        hls.loadSource(this.media.src);
        hls.attachMedia(this.media);
        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          if (["metadata", "none"].includes(this.options.preload)) {
            setTimeout(() => {
              hls.stopLoad();
            }, 200);
          }

          this.player.on("play", function () {
            hls.startLoad();
          });
        });

        // hls.attachMedia(this.mediaElement);

        this.player?.on("languagechange", () => {
          setTimeout(() => (hls.subtitleTrack = this.player.currentTrack), 50);
        });
      }
    };
    let hlsScript = document.getElementById("h5vp-hls-js");
    // con;
    if (!hlsScript) {
      hlsScript = document.createElement("script");
      hlsScript.setAttribute("id", "h5vp-hls-js");
      hlsScript.src = this.local?.hls;
      document.getElementsByTagName("head")[0]?.prepend(hlsScript);
      hlsScript?.addEventListener("load", () => initializeHls());
    } else {
      initializeHls();
    }
  }

  videoDash() {
    let dashScript = document.getElementById("h5vp-dash-js");
    if (!dashScript) {
      dashScript = document.createElement("script");
      dashScript.setAttribute("id", "h5vp-dash-js");
      dashScript.src = this.local?.dash;
      document.getElementsByTagName("head")[0]?.prepend(dashScript);
      dashScript.addEventListener("load", () => {
        const dash = dashjs.MediaPlayer().create();
        dash.initialize(this.media, this.media.src, true);
      });
    } else {
      const dash = dashjs.MediaPlayer().create();
      dash.initialize(this.media, this.media.src, true);
    }
  }

  controlTouchAction() {
    // enable touch only on mobile/tablet.
    if (document.body.offsetWidth > 992) {
      return false;
    }

    const wrapper = this.player.elements?.container;

    if (!wrapper) {
      return false;
    }

    const player = this.player;
    const videoWrapper = wrapper.querySelector(".plyr__video-wrapper");
    const span = document.createElement("span");
    span.classList.add("seekSecond");
    videoWrapper.appendChild(span);

    // disable double click fullscreen | remove default event;
    player.eventListeners.forEach((eventListener) => {
      if (eventListener.type === "dblclick") {
        wrapper.removeEventListener(eventListener.type, eventListener.callback, eventListener.options);
      }
    });
    // console.log("got");

    videoWrapper.parentNode.addEventListener("dblclick", (e) => {
      let rect = e.target.getBoundingClientRect();
      let x = e.clientX - rect.left; //x position within the element.
      if (this.player.fullscreen.active) {
        if (videoWrapper.offsetWidth - 150 < x && videoWrapper.offsetWidth / 2 < x) {
          this.player.forward(this.options.seekTime);
          span.innerText = this.options.seekTime + " sec";
        } else if (videoWrapper.offsetWidth / 2 > x && x < 150) {
          this.player.rewind(this.options.seekTime);
          span.innerText = "-" + this.options.seekTime + " sec";
        }
        setTimeout(() => {
          span.innerText = "";
        }, 1000);
      }

      if (x > 150 && x < videoWrapper.offsetWidth - 150) {
        this.player.fullscreen.toggle();
      }
    });

    //touch
    wrapper.addEventListener(
      "touchstart",
      (e) => {
        if (!this.player.fullscreen.active) return;
        this.touchClientX = e.touches[0].clientX;
        this.touchClientY = e.touches[0].clientY;
      },
      false
    );

    wrapper.addEventListener(
      "touchend",
      (e) => {
        if (!this.player.fullscreen.active) return;
        let deltaX, deltaY;
        deltaX = e.changedTouches[0].clientX - this.touchClientX;
        deltaY = e.changedTouches[0].clientY - this.touchClientX;

        if (deltaX > 5) {
          player.forward(parseInt(deltaX) / 10);
          span.innerText = parseInt(deltaX / 10) + " sec";
        } else if (deltaX < -5) {
          player.rewind(Math.abs(deltaX) / 10);
          span.innerText = parseInt(deltaX / 10) + " sec";
        }

        setTimeout(() => {
          span.innerText = "";
        }, 1000);
      },
      false
    );
  }

  /**
   *
   * @param {*} player
   */
  pauseOther() {
    if (location.pathname.includes("wp-admin")) return false;
    if (window.hpublic?.pauseOther) {
      this.player.on("play", () => {
        const id = this.player.elements.fullscreen?.dataset?.uniqueId;
        const players = document.querySelectorAll(`video:not([data-unique-id="${id}"] video, a video)`);

        players.forEach((player) => player.pause());
      });
    }
  }

  domAttributesToObjectList(elements) {
    const resultArray = [];

    elements.forEach((element) => {
      const attributes = element.attributes;
      const result = {};

      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        result[attribute.name] = attribute.value;
      }

      resultArray.push(result);
    });

    return resultArray;
  }
}

export const extractFileNamesWithoutExtension = (filePath) => {
  const parts = filePath.split("/");
  const fileNameWithExtension = parts[parts.length - 1];
  const fileNameWithoutExtension = fileNameWithExtension.replace(/\.[^/.]+$/, "");
  return fileNameWithoutExtension;
};

export default MyPlayer;
